.root {
    @apply flex items-center px-4 py-2 text-sm leading-5 font-normal whitespace-nowrap;

    &:not(.disabled) {
        @apply cursor-pointer;
    }

    &.regular:not(.disabled) {
        @apply text-gray-700 hover:bg-blue-100 hover:text-blue-700;
    }

    &.custom:not(.disabled) {
        @apply bg-gray-100 text-gray-700 hover:bg-gray-200;
    }

    &.danger:not(.disabled) {
        @apply bg-red-100 text-red-700 hover:bg-red-200;
    }

    &.disabled {
        @apply opacity-50 cursor-default hover:bg-transparent pointer-events-none;
    }
}
