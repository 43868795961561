.root {
    @apply py-5 pl-4 pr-4 sm:pl-6 flex items-center justify-between cursor-pointer hover:bg-gray-50;
}

.title {
    @apply text-sm leading-5 font-medium;
}

.description {
    @apply mt-0.5 text-sm leading-5 font-normal;
}

.root.regular {
    .title {
        @apply text-gray-900;
    }

    .description {
        @apply text-gray-500;
    }
}

.root.danger {
    .title {
        @apply text-red-900;
    }

    .description {
        @apply text-red-800;
    }
}
