.horizontalLines {
    @apply col-start-1 col-end-2 row-start-1 grid;
    margin-left: calc(-4rem - 2px);
    grid-template-columns: 4rem 1fr;
    grid-template-rows: 5fr 3fr 5fr 4fr;
    gap: 0 0;

    @media (min-width: 768px) {
        margin-left: calc(-6rem - 2px);
        grid-template-columns: 6rem 1fr;
    }
}

.barPeriod2_7,
.barPeriod7_12,
.barPeriod12_15,
.barPeriod15_20,
.barPeriod20_24 {
    @apply border-r-2 -mr-0.5;
}

.barPeriod7_12 {
    @apply pt-px;
    grid-area: 1 / 1 / 2 / 2;
}

.barPeriod12_15 {
    @apply pt-px;
    grid-area: 2 / 1 / 3 / 2;
}

.barPeriod15_20 {
    @apply pt-px;
    grid-area: 3 / 1 / 4 / 2;
}

.barPeriod20_24 {
    @apply pt-px;
    grid-area: 4 / 1 / 5 / 2;
}

.period7_12 {
    grid-area: 1 / 1 / 1 / 6;
}

.period12_15 {
    grid-area: 2 / 1 / 2 / 6;
}

.period15_20 {
    grid-area: 3 / 1 / 3 / 6;
}

.period20_24 {
    grid-area: 4 / 1 / 4 / 6;
}

.barPeriod {
    @apply relative;
}

.barPeriodLabel {
    @apply px-2 py-1 text-base leading-6 text-gray-800;
}
