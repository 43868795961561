.root {
    @apply text-base relative overflow-hidden w-full h-full;

    .icon {
        @apply inline-flex w-10 h-10 rounded-full mr-2 items-center justify-center;
    }

    .iconRight {
        @apply inline-flex w-10 h-10 rounded-full ml-2 items-center justify-center;
    }

    @media (min-width: 1023px) {
        @apply text-lg;

        .icon {
            @apply mr-4;
        }

        .iconRight {
            @apply ml-4;
        }
    }

    &.tv {
        @media (min-width: 2160px) {
            @apply text-3xl;

            .icon {
                @apply mr-6;
            }

            .iconRight {
                @apply ml-6;
            }
        }
    }
}

.root .icon svg {
    @apply w-7 h-7;
}

.root.danger {
    @apply bg-red-50 text-red-800;
}

.root.in-progress {
    background-color: #059669;
    @apply text-white;

    .icon {
        @apply bg-white;
    }
}

.root.common {
    @apply bg-gray-100 text-gray-600;

    .icon {
        @apply bg-gray-300 text-gray-900;
    }
}

.root.orange {
    background-color: #f97316;
    @apply text-white;

    .icon {
        @apply bg-white text-yellow-900;
    }
}

.root.light-orange {
    background-color: #ffedd5;
    color: #c2410c;

    .icon {
        background-color: #c2410c;
        color: #ffffff;
    }
}

.root.blue {
    background-color: #3b82f6;
    @apply text-white;

    .icon {
        @apply bg-white text-blue-900;
    }
}

.root.cyan {
    background-color: #cffafe;
    color: #0e7490;

    .icon {
        background-color: #fff;

        svg rect {
            fill: #0e7490;
        }

        svg path {
            fill: #0e7490;
        }
    }
}

.root.cyan-inverse {
    background-color: #0e7490;
    color: #cffafe;

    .icon {
        color: #0e7490;
        background-color: #fff;
    }
}

.root.purple {
    @apply bg-purple-800 text-white;

    .icon {
        @apply bg-purple-50 text-purple-900;
    }
}

.root.light-purple {
    @apply bg-purple-50 text-purple-800;

    .icon {
        @apply bg-white text-purple-900;
    }
}

.root.light-purple2 {
    @apply bg-purple-50 text-purple-800;

    .icon {
        @apply bg-purple-800 text-white;
    }
}

.root.gray {
    @apply bg-gray-400 text-gray-600;

    .icon {
        @apply bg-gray-300 text-gray-900;
    }
}

.root.cancelled {
    @apply bg-black text-white;
}

.root .inner {
    @apply absolute inset-0 z-10 flex items-center justify-center text-center font-semibold;
}

.root .progress {
    @apply absolute inset-0 z-0;
}

.root.in-progress .progress {
    background-color: #064e3b;
}

.root.in-progress .icon {
    color: #064e3b;
}

.root.in-progress.oversize .progress {
    background-color: #c2410c;
}

.root.in-progress.oversize .icon {
    color: #c2410c;
}
