.root {
    @apply inline-flex items-center p-1 border border-transparent bg-transparent text-xs font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer;
}

.root:not(.disabled) {
    @apply text-blue-500 hover:text-blue-600;
}

.disabled {
    @apply text-gray-500 bg-gray-100 pointer-events-none;
}
