.root {
    @apply inline-flex items-center rounded-full leading-normal text-xs font-medium;

    &.sm {
        @apply px-2.5 py-0.5;

        svg {
            @apply w-3 h-3;
        }
    }

    &.md {
        @apply px-3 py-1;

        svg {
            @apply w-4 h-4;
        }
    }

    &.new {
        @apply bg-gray-100 text-gray-800;
    }

    &.today {
        @apply bg-yellow-100 text-yellow-800;
    }

    &.ordered {
        @apply bg-yellow-100 text-yellow-800;
    }

    &.archive {
        @apply bg-yellow-100 text-yellow-800;
    }

    &.accepted {
        @apply bg-yellow-100 text-yellow-800;
    }

    &.clarify {
        @apply bg-yellow-100 text-yellow-800;
    }

    &.done {
        @apply bg-green-100 text-green-800;
    }

    &.rejected {
        @apply bg-gray-900 text-white;
    }

    .label {
        @apply whitespace-nowrap;
    }

    .children {
        &--icon-left {
            @apply mr-1;
        }

        &--icon-right {
            @apply ml-1;
        }
    }
}
