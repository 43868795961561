.root {
    @apply relative p-4 border rounded w-full h-24 shadow-xl pointer-events-auto;
}

.title {
    @apply text-lg leading-6 font-medium;
}

.description {
    @apply text-sm leading-5 text-gray-500;
}

.title + .description {
    @apply mt-1;
}

.close {
    @apply absolute top-2 right-2 bg-white flex rounded-md text-gray-500 hover:text-gray-800 focus:outline-none shadow-lg justify-center items-center w-5 h-5;
}

.root.regular {
    @apply bg-white border-gray-200;

    .title {
        @apply text-gray-900;
    }
}

.root.danger {
    @apply bg-red-50 border-red-200;

    .title {
        @apply text-red-900;
    }
}
