.inner {
    width: 100%;
    height: calc(100vh - 30px);

    @media (min-width: 640px) {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    @media (min-height: 768px) {
        height: calc(100vh - 150px);
    }

    @apply inline-block pt-6 px-6 sm:px-12 sm:pt-12 pb-0 max-w-5xl align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle;
}

.prevNextDayButton {
    display: none;

    @media (min-width: 640px) {
        display: block;
    }
}

.role-header:after,
.role-header:before {
    @apply w-full h-px bg-gray-300;
    content: '';
}

.role-header:before {
    @apply mr-1;
}

.role-header:after {
    @apply ml-1;
}
