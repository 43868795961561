.root {
    @apply w-full flex h-full items-start justify-between p-4 space-x-4 bg-white border border-gray-100 rounded-lg cursor-pointer hover:shadow-lg;

    &.current {
        @apply shadow-lg;
    }

    .name {
        @apply text-sm leading-5 font-medium text-gray-600;
    }

    .date {
        @apply flex;
    }

    .status {
        @apply inline-flex;
    }

    &.done {
        @apply bg-gray-100;

        .name {
            @apply text-gray-400;
        }
    }

    &.expired {
        @apply bg-red-50 border-red-200;

        .name {
            @apply text-red-600;
        }
    }

    &.today {
        @apply bg-blue-50 border-blue-200;

        .name {
            @apply text-blue-600;
        }
    }

    &.coming {
        @apply border-blue-200;
    }

    &.rejected {
        @apply border-gray-900 bg-gray-500;

        .name {
            @apply text-gray-800;
        }
    }
}
