.occupancyContainer {
    @apply ml-1 flex items-center;

    &.locked {
        @apply text-red-600;
    }
}

.occupancy {
}

.lockButtonContainer {
    @apply ml-2;
}
