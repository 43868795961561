.comment {
    @apply mt-2 border rounded-md px-4 py-4 border-gray-100 bg-gray-50;

    &.note {
        @apply mt-0;
    }

    .body {
        strong {
            @apply font-medium;
        }
    }
}
