.root {
    @apply relative inline-flex border-2 border-white bg-white rounded-full;
}

.circle-progress-bar {
    @apply transform -rotate-90;
}

.icon-container {
    @apply absolute inset-0 flex items-center justify-center;
}

.icon-circle {
    @apply p-1 bg-white rounded-full;
}

.icon {
    @apply w-4 text-gray-500;
}

.undone {
    &.root {
        @apply bg-gray-200;
    }

    .circle {
        stroke: #6b7280;
    }

    .icon {
        @apply text-gray-500;
    }
}

.in-progress {
    &.root {
        @apply bg-yellow-200;
    }

    .circle {
        stroke: #f97316;
    }
}

.done {
    &.root {
        @apply bg-green-200;
    }

    .circle {
        stroke: #10b981;
    }

    .icon-circle {
        @apply bg-green-200;
    }

    .icon {
        @apply text-green-500;
    }
}

.rejected {
    &.root {
        @apply bg-gray-200;
    }

    .circle {
        stroke: #9ca3af;
    }

    .icon-circle {
        @apply bg-gray-200;
    }

    .icon {
        @apply text-gray-500;
    }
}
