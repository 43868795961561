@page {
    size: A4;
    margin: 0;
}

@media print {
    html,
    body,
    #root {
        height: auto !important;
    }

    body {
        margin: 14mm 8mm 14mm 8mm;
    }

    #list-layout-header {
        zoom: 49%;
    }

    #list-layout #list-layout-content {
        zoom: 49%;
    }

    #list-layout.daily-schedule #list-layout-content {
        zoom: 100%;
    }

    #list-layout.hourly-schedule #schedule-vertical-lines {
        display: grid;
        visibility: visible;
    }

    #schedule-scrolling-component {
        height: auto !important;
    }

    #schedule-vertical-lines,
    .schedule-room-list-container,
    .daily-grid-container,
    .hourly-grid-container {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }
}
