.event {
    @apply text-base rounded font-light;

    &.tv {
        @media (min-width: 2160px) {
            @apply text-lg rounded-xl;
        }
    }

    &.finished {
        @apply mx-2;

        .inner {
            @apply bg-gray-50 rounded;
        }
    }

    &.cancelledOrRejected {
        .inner {
            @apply bg-gray-500;
        }
    }

    &.current {
        .inner {
            box-shadow: 0 16px 56px rgba(0, 0, 0, 0.56);
        }
    }

    &.current,
    &.next {
        .inner {
            @apply bg-white divide-y divide-gray-200;
        }

        &.tv .inner {
            @media (min-width: 2160px) {
                @apply divide-y-2;
            }
        }
    }

    &.further {
        @apply pb-0;

        .inner {
            @apply bg-white;
        }
    }

    .inner {
        @apply rounded-md;
    }

    &.tv .inner {
        @media (min-width: 2160px) {
            @apply rounded-xl;
        }
    }

    .content-container {
        @apply divide-y divide-gray-200;
    }

    &.tv .content-container {
        @media (min-width: 2160px) {
            @apply divide-y-2;
        }
    }
}

.event.finished {
    .patient-container {
        @apply px-2 py-2 text-lg flex items-center text-gray-600 leading-normal font-medium;

        .time {
            @apply flex-shrink-0 text-base;
        }
    }

    .patient {
        @apply ml-2;
    }

    &.cancelledOrRejected {
        @apply text-gray-100;

        .patient-container {
            @apply text-gray-100 pb-0.5;
        }

        .cancelled-or-rejected-info {
            @apply p-2 pt-0.5 font-medium text-sm border-t border-gray-400;
        }
    }

    &.tv {
        @media (min-width: 2160px) {
            .inner {
                @apply rounded-xl;
            }

            .patient-container {
                @apply px-6 py-3 text-3xl;

                .time {
                    @apply text-3xl;
                }
            }

            .patient {
                @apply ml-6;
            }

            &.cancelledOrRejected {
                .cancelled-or-rejected-info {
                    @apply px-6 pt-2 pb-4 text-xl border-t-2;
                }
            }
        }
    }
}

.event.current {
    .patient {
        @apply py-4 px-2 space-x-2 leading-normal text-gray-900 font-semibold;
        font-size: 1.675rem;
        line-height: 2rem;
    }

    &.tv {
        @media (min-width: 2160px) {
            .patient {
                @apply py-10 px-6 space-x-4 leading-none;
                font-size: 2.5rem;
            }
        }
    }
}

.event.next {
    .patient-container {
        @apply relative px-2 py-2 text-2xl leading-tight font-semibold text-gray-800;
    }

    .patient {
        @apply mt-0 space-x-2;
        font-size: 1.275rem;
    }

    .doctor {
        @apply justify-start;
    }

    &.tv {
        @media (min-width: 2160px) {
            .patient-container {
                @apply px-4 py-3 text-4xl leading-none;
            }

            .patient {
                @apply mt-2 space-x-4 leading-none;
                font-size: 2rem;
            }
        }
    }
}

.event.further {
    .inner {
        @apply pt-px;
    }

    .patient-container {
        @apply relative px-1 py-1 text-2xl leading-tight font-semibold text-gray-800 m-1 rounded;

        &.new:not(.hasProgress) {
            @apply bg-red-100;
        }

        &.new.hasProgress {
            @apply bg-yellow-100;
        }

        &.canceled {
            @apply bg-black text-white;
        }

        &.rejected {
            @apply bg-gray-100;
        }

        &.accepted {
            @apply bg-green-100;
        }
    }

    .patient {
        @apply space-x-2;
        font-size: 1.275rem;
    }

    .doctor {
        @apply justify-start;
    }

    &.tv {
        @media (min-width: 2160px) {
            .patient-container {
                @apply px-4 py-3 text-4xl rounded-lg leading-none;
            }

            .patient {
                @apply mt-2 space-x-4 leading-none;
                font-size: 2rem;
            }
        }
    }
}

.operation-detail-list {
    @apply p-2 text-base leading-tight space-y-3;

    &-item-label {
        @apply text-xs text-gray-600 font-normal;
    }

    &-item-value {
        @apply mt-0.5 leading-5 text-gray-800 font-medium;
        font-size: 1rem;
    }

    .medical-equipment-list {
        @apply mt-1 -mb-1;

        .medical-equipment-list-item {
            @apply inline-block bg-yellow-400 rounded px-1 mr-1 mb-1 text-gray-900 font-medium leading-6;
            font-size: 0.9rem;
        }
    }

    .implant-list {
        @apply flex flex-wrap -mb-1;

        &-status {
            @apply my-2;
        }

        &-status-label {
            @apply inline-block rounded bg-white px-2 py-0.5 leading-normal text-sm ring-1 ring-inset;
        }

        &-item {
            @apply inline-flex font-medium bg-gray-100 text-gray-800 rounded px-1 mr-1 mb-1 leading-6;
            font-size: 0.9rem;
        }
    }
}

.procedure {
    @apply px-4 pb-4 text-xl text-gray-600 text-center leading-10;
}

.message {
    @apply py-4 px-4 text-xl leading-none text-gray-600 font-medium text-center;
}

.doctor {
    @apply text-base px-2 py-1.5 flex items-center justify-center text-center text-gray-800 font-semibold leading-normal;

    svg {
        @apply mr-2 w-4 h-auto;
    }
}

.fogging {
    @apply flex justify-center p-2;

    &-label {
        @apply inline-block bg-purple-400 px-2 rounded text-white font-medium text-base;
    }
}

.surgicalTeam {
    @apply px-2 py-2 text-base border-b-2 border-gray-200 text-gray-600 leading-tight font-normal;

    .icon-container {
        @apply mr-2 top-1 relative flex-shrink-0;

        svg {
            @apply w-4 h-auto;
        }
    }
}

.event.further .surgicalTeam {
    @apply border-t border-0 border-gray-200 border-solid;
}

.timer-container {
    @apply flex flex-col p-2 space-y-1 space-x-0;

    @media (min-width: 1023px) {
        @apply flex-row space-x-2 space-y-0;
    }
}

.timer-container .left {
    @apply w-auto flex px-2 py-2 space-y-0 space-x-4 flex-shrink-0 bg-gray-50 rounded-md leading-normal;

    @media (min-width: 1023px) {
        @apply block space-y-1 space-x-0;
        width: 8rem;
    }
}

.timer-container .left .row {
    @apply flex items-center;

    svg {
        @apply w-4 h-auto;
    }

    svg + span {
        @apply ml-2;
    }

    &.row-1 {
        @apply text-sm text-gray-500;
    }

    &.row-2 {
        @apply text-xl text-gray-600 font-medium;
    }
}

.timer-container .right {
    @apply px-2 py-2 flex items-center justify-between flex-1 bg-gray-100 rounded-md;

    svg {
        @apply w-8 h-auto;
    }
}

.timer-container .right .label {
    @apply text-sm text-gray-500;
}

.timer {
    @apply text-2xl text-gray-900 font-semibold leading-tight;
}

.progress-container {
    @apply px-2 py-2;
}

.progress-status-container {
    height: 58px;
    @apply rounded-md overflow-hidden;
}

.event.current.oversize {
    .timer {
        color: #c2410c;
    }
}

.description {
    @apply px-2 py-1 bg-blue-50 rounded-md;
}

.duration-container {
    @apply absolute flex items-center justify-end text-gray-800 font-medium right-2 top-1 text-base leading-6;

    .clock-icon {
        @apply inline-flex mr-2 w-5 h-5;
    }
}

@media (min-width: 2160px) {
    .tv {
        .duration-container {
            @apply right-4 top-2 text-3xl;

            .clock-icon {
                @apply mr-4 w-8 h-8;
            }
        }

        .operation-detail-list {
            @apply p-4 space-y-4;

            &-item-label {
                @apply text-xl;
            }

            &-item-value {
                @apply leading-none;
                font-size: 1.75rem;
            }

            .medical-equipment-list {
                @apply mt-2 -mb-2;

                .medical-equipment-list-item {
                    @apply px-2 py-0 rounded-md mr-2 mb-2 leading-normal;
                    font-size: 1.4rem;
                }
            }

            .implant-list {
                @apply -mb-1;

                &-status {
                    @apply my-3;
                }

                &-status-label {
                    @apply rounded-md px-2 py-0 leading-normal;
                    font-size: 1.4rem;
                }

                &-item {
                    @apply px-2 py-0 rounded-md mr-2 mb-2 leading-normal;
                    font-size: 1.4rem;

                    svg {
                        @apply mt-2.5;
                    }
                }
            }
        }

        .procedure {
            @apply px-4 pb-8 text-2xl;
        }

        .description {
            @apply px-4 py-2;
        }

        .message {
            @apply px-4 py-8 text-3xl;
        }

        .doctor {
            @apply px-4 py-3;
            font-size: 1.675rem;

            svg {
                @apply mr-4 w-7;
            }
        }

        .fogging {
            @apply flex justify-center p-4;

            &-label {
                @apply inline-flex bg-purple-400 px-3 rounded-md text-white font-medium leading-normal;
                font-size: 1.4rem;
            }
        }

        .surgicalTeam {
            @apply px-4 py-3 leading-tight;
            font-size: 1.675rem;

            .icon-container {
                @apply mr-4 top-1;

                svg {
                    @apply w-7;
                }
            }
        }

        .timer-container {
            @apply px-4 py-3;
        }

        .timer-container .left {
            @apply px-4 py-5 space-y-4 rounded-lg;
            width: 14.3rem;
        }

        .timer-container .left .row {
            svg {
                @apply w-6;
            }

            svg + span {
                @apply ml-4;
            }

            &.row-1 {
                font-size: 1.875rem;
            }

            &.row-2 {
                font-size: 2.25rem;
            }
        }

        .timer-container .right {
            @apply px-8 py-4 rounded-lg;

            svg {
                @apply w-12;
            }
        }

        .timer-container .right .label {
            @apply text-xl leading-none;
        }

        .timer {
            @apply mt-2 text-5xl leading-none;
        }

        .progress-container {
            @apply px-4 py-3;
        }

        .progress-status-container {
            height: 92px;
            @apply rounded-lg;
        }
    }
}
