.success {
    @apply bg-green-50 text-green-800;

    .alertIcon {
        @apply text-green-400;
    }

    .Icon {
        @apply text-green-500;
    }
}

.warning {
    @apply bg-yellow-50 text-yellow-800;

    .alertIcon {
        @apply text-yellow-400;
    }

    .Icon {
        @apply text-yellow-500;
    }
}

.danger {
    @apply bg-red-50 text-red-800;

    .alertIcon {
        @apply text-red-400;
    }

    .Icon {
        @apply text-red-500;
    }
}

$duration: 300ms;

.alertEnter {
    opacity: 0;
}
.alertEnterActive {
    opacity: 1;
    transition: opacity $duration, transform $duration;
}
.alertExit {
    opacity: 1;
}
.alertExitActive {
    opacity: 0;
    transition: opacity $duration, transform $duration;
}

.alertAppear {
    opacity: 0;
}

.alertAppearActive {
    opacity: 1;
    transition: opacity $duration, transform $duration;
}
