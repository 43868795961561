.root {
    @apply fixed inset-0 z-20 flex flex-col w-0 md:w-12 bg-gray-800 transition-all;

    &.isExpanded {
        @apply w-64;
    }

    header {
        @apply flex flex-shrink-0 items-center justify-center text-white h-16;

        .menuExpander {
            @apply flex px-4 items-center text-white outline-none h-full w-full;

            svg {
                @apply w-6;
            }
        }

        .header {
            @apply flex items-center justify-between w-full h-full px-4;
        }
    }

    .nav {
        @apply space-y-1 flex flex-1 flex-col py-2 items-start;
    }

    .item-container {
        @apply flex flex-col w-full flex-shrink-0 relative px-2;
    }

    .navItem {
        @apply flex w-full flex-shrink-0 items-center justify-start text-white h-9 cursor-pointer;

        .icon-container {
            @apply flex flex-shrink-0 items-center justify-center w-8 h-8 rounded-md hover:bg-blue-500;

            &.active {
                @apply bg-blue-900 text-white;
            }
        }

        .icon {
            @apply flex-shrink-0 w-5 h-5;
        }

        .title {
            @apply ml-2 flex-1 text-left whitespace-nowrap text-sm leading-5 font-normal invisible;
        }
    }

    .navItemPanel {
        @apply space-y-1 z-10;
    }

    .subItem {
        @apply h-9 text-sm text-gray-400 hover:text-white;

        &.active {
            @apply bg-blue-900 text-white hover:text-white hover:bg-blue-500;
        }

        .title {
            @apply pl-8;
        }
    }

    &.isExpanded {
        .nav {
            @apply overflow-y-auto;
        }

        .navItem {
            @apply rounded-md hover:bg-blue-500;

            .icon-container {
                @apply flex items-center justify-center w-8 h-8 rounded-md hover:bg-blue-500 hover:text-white;
            }

            .title {
                @apply whitespace-nowrap text-sm leading-5 font-normal visible;
            }

            &:hover {
                .icon-container {
                    @apply bg-blue-500;
                }
            }
        }

        .navItemPanel {
            &.closed {
                display: none;
            }
        }
    }

    &:not(.isExpanded) {
        .nav {
            @apply hidden md:flex;
        }

        .navItemPanel {
            @apply bg-gray-800 absolute -top-1 left-12 mt-0 rounded-r-md;
            display: none;

            .title {
                @apply visible pl-5 pr-10 m-0;
            }
        }

        .item-container {
            &:hover {
                > .navItemPanel {
                    @apply mt-0 p-1;
                    display: block;

                    .subItem {
                        @apply rounded-md;

                        &:hover {
                            @apply bg-blue-500;
                        }
                    }
                }
            }
        }

        .subItem {
            .icon-container {
                @apply rounded-md hover:bg-blue-500;
            }
        }
    }
}
