.root {
    @apply inline-flex text-sm leading-5 text-gray-400 align-middle;

    &.new {
    }

    &.done {
        @apply text-gray-400;
    }

    &.expired {
        @apply text-red-600;
    }

    &.today {
        @apply text-blue-600;
    }

    &.coming {
    }

    &.rejected {
        @apply text-gray-400;
    }

    &.error {
        @apply text-red-600;
    }
}
