.root {
    @apply py-0 leading-none;

    &.sm,
    &.md,
    &.lg {
        @apply px-2;
    }

    &.sm {
        height: 1.875rem;
    }

    &.md {
        height: 2.375rem;
    }

    &.lg {
        height: 2.5rem;
    }
}
