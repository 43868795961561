.root {
    @apply bg-white mt-0.5 p-2 shadow-xl rounded-md flex;

    .dayPickerRoot {
        @apply m-1;
    }

    .captionLabel {
        @apply inline-flex items-center font-medium text-lg text-center px-1 leading-8;
    }

    .headCell {
        @apply font-thin pb-2 capitalize text-center;
    }

    .cell {
        @apply p-px w-10 h-10;

        // sundays style
        &:nth-of-type(7) {
            .day {
                &:not([disabled]):not(.blockedDay):not(.selected):not(:hover) {
                    @apply bg-white text-gray-400;
                }
            }
        }
    }

    .day {
        @apply w-full h-full rounded;

        // to override default hover
        &:not([disabled]):not(.rdp-day_selected) {
            &:focus {
                @apply border-blue-500;
            }

            &:hover {
                @apply bg-blue-100;
            }
        }
    }

    .today {
        @apply text-blue-500;
    }

    .selected {
        @apply bg-blue-50 rounded-none;
    }

    .blockedDay:not([disabled]) {
        @apply bg-red-100 text-red-800 rounded;

        &.day {
            &:hover,
            &:focus {
                @apply bg-red-200 text-red-800;
            }

            &:focus {
                @apply border-red-400;
            }
        }
    }

    .rangeStart {
        @apply text-blue-500 border-blue-500 border-2 rounded-l;
    }

    .rangeEnd {
        @apply text-blue-500 border-blue-500 border-2 rounded-r;
    }

    .navIcon {
        @apply w-5 h-5;
    }

    .caption {
        @apply relative mb-2 text-center;

        .navButton {
            @apply absolute top-0 bg-gray-100 text-gray-500 px-3 py-1 rounded-md;

            &:not([disabled]):not(.rdp-day_selected) {
                &:hover {
                    @apply bg-gray-200;
                }
            }

            &:focus {
                @apply bg-gray-200 border-transparent ring-2 ring-offset-2 ring-gray-400;
            }
        }

        .navButton[name='previous-month'] {
            @apply left-0;
        }

        .navButton[name='next-month'] {
            @apply right-0;
        }
    }

    &.isMultipleMonths {
        .captionStart {
            @apply mr-0;

            .navButton {
                @apply left-0 right-auto;
            }
        }

        // to reset default styles
        .captionEnd {
        }
    }

    &.isSingleDate {
        .selected {
            &.blockedDay {
                @apply text-red-700 border-red-500;
            }

            @apply text-blue-500 border-blue-500 border-2 rounded pointer-events-none;
        }
    }

    .sideMenu {
        @apply w-48 divide-gray-200 divide-y mr-4;
    }
}
