.root {
}

.fileUploader {
    @apply mt-1 h-36 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-200 border-dashed rounded-md;

    &.uploaded {
        @apply border-blue-500 text-blue-500;
    }
}
