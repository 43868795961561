.root {
    @apply bg-gray-400 h-full;
}

.clock {
    @apply flex p-2 items-center leading-normal justify-center bg-gray-900 font-semibold text-white text-xl;
}

.topbar {
    @apply flex p-2 justify-center items-center bg-gray-900 text-white;

    .arrow-icon {
        @apply w-4 cursor-pointer;

        &.disabled {
            @apply cursor-default text-gray-500;
        }
    }

    .topbar-content {
        @apply flex items-center justify-center;
        width: 344px;
    }

    .topbar-item-divider {
        @apply w-px h-5 bg-gray-700;
    }

    .topbar-item {
        @apply px-4;
    }

    .topbar-clock {
        @apply font-bold;
    }
}

.operating-room-header {
    @apply flex relative items-center justify-center text-gray-900 font-medium text-2xl p-2;
}

.operating-room-list {
    @apply px-2 pb-10;
}

.operating-room-list-item + .operating-room-list-item {
    @apply mt-2;

    @media (min-width: 768px) {
        @apply mt-4;
    }
}

.content {
    @apply max-h-full;
    width: 100%;
    min-width: 768px;
    display: flex;
    flex-direction: column;
}

.columns-container {
    display: flex;
    width: 100%;
}

.operating-room-header,
.operating-room-column {
    min-width: 256px;
    width: 33.3333%;
}

@media (min-width: 768px) {
    .clock {
        @apply text-base;
    }

    .operating-room-header {
        @apply p-4;
    }
}

@media (min-width: 2160px) {
    .tv {
        .clock {
            @apply p-8 text-4xl;
        }

        .operating-room-header {
            @apply p-8 text-4xl;
        }

        .operating-room-list {
            @apply px-4;
        }

        .operating-room-list-item + .operating-room-list-item {
            @apply mt-6;
        }
    }
}
