.root[type='text'],
.root[type='email'],
.root[type='url'],
.root[type='password'],
.root[type='number'],
.root[type='date'],
.root[type='datetime-local'],
.root[type='month'],
.root[type='search'],
.root[type='tel'],
.root[type='time'],
.root[type='week'],
.root[multiple],
textarea.root,
select.root {
    @apply appearance-none block w-full shadow-sm focus:outline-none;

    &:not(.error) {
        @apply rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500;
    }

    &.xs {
        @apply px-2 py-1 h-7 border-gray-300 rounded-md text-xs;
    }

    &.sm {
        @apply px-2 py-1.5 border-gray-300 rounded-md text-sm;
    }

    &.md {
        @apply px-3 py-2 text-sm;
    }

    &.lg {
        @apply px-3 py-0 text-base leading-10;
        height: 2.625rem;

        &::placeholder {
            @apply text-base;
        }
    }

    &.error {
        @apply pr-10 rounded-md border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 ring-1 ring-offset-0 ring-red-300;
    }

    &.disabled {
        @apply bg-gray-100;
    }

    &.alignLeft {
        @apply text-left;
    }

    &.alignCenter {
        @apply text-center px-3;
    }

    &.xs.hasPostfix {
        @apply pr-6;
    }

    &.sm.hasPostfix {
        @apply pr-7;
    }

    &.md,
    &.lg {
        &.hasPostfix {
            @apply pr-10;
        }
    }
}

textarea.root {
    @apply text-sm;
}

.root {
    @apply text-gray-700;
}

.trailing-addon {
    @apply pointer-events-none absolute inset-y-0 right-0 text-gray-500 sm:text-sm flex items-center pr-3;

    &.xs {
        @apply text-xs;
    }
}
