.root {
    &.sm {
        @apply py-0 leading-none;
        height: 1.875rem;

        @media screen and (max-width: 765px) {
            span {
                margin: 0 !important;

                &.label {
                    display: none;
                }
            }
        }
    }
}
