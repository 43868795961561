.inner {
    @apply inline-block px-4 pt-5 pb-4 w-full max-w-lg align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle;
}

.title {
    @apply text-lg leading-6 font-medium text-gray-900 text-center;
}

.action-container {
    @apply mt-6 grid grid-cols-2 gap-3;
}
